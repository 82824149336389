import React, { useState, useEffect, useContext } from "react";
import DataTable from "../components/DataTableComponents";
import MUIDataTable from "mui-datatables";
import IconUI from "../components/iu/icon_ui";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Tooltip } from "@mui/material";
import LoaderComponent from "../components/admin/LoaderComponent";
import {
  Box,
  Modal,
  TextField,
  MenuItem,
  Autocomplete,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { FaFileSignature, FaHandsHelping } from "react-icons/fa";
import { onlyNumber } from "../utils/inputsRules";
import { services } from "../services/api";
import { NotificacionContext } from "../context/notificacion_context";
import { useNavigate } from "react-router-dom";
import "dayjs/locale/es";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { RiFileExcel2Line } from "react-icons/ri";

const getMuiTheme = () =>
  createTheme({
    components: {
      MUIDataTable: {
        styleOverrides: {
          root: {
            borderRadius: "30px !important",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: "#6A0F49 !important",
            //backgroundColor: '#bec3c9 !important',
            color: "white",
          },
        },
      },
    },
  });

const Ciudadanos = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #661d40",
    //border: '2px solid #661d40',
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    overflowY: "scroll",
    height: 600,
    borderRadius: "40px",
    scrollbarWidth: "none",
  };

  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #661d40",
    //border: '2px solid #661d40',
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    overflowY: "scroll",
    height: "auto",
    borderRadius: "40px",
    scrollbarWidth: "none",
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#005cbf"),
    backgroundColor: "#661d40",
    //backgroundColor: ' #8d949e',
    "&:hover": {
      backgroundColor: "#661d40",
      //backgroundColor: ' #8d949e',
    },
    borderRadius: "40px",
  }));

  //state del loading
  const [loader, setLoader] = useState({
    loading: true,
  });

  const { showNotification } = useContext(NotificacionContext);

  // state del componente
  const [state, setState] = useState({
    ciudadanos: [],
    municipios: [],
    localidades: [],
    ciudadanoSeleccionado: {},
    // openModalEditar: false,
    // openModalEliminar: false,
  });

  const goTo = useNavigate();

  const [titulosState, setTitulos] = useState({
    data: [],
  });
  const [cargosState, setCargos] = useState({
    data: [],
  });

  // state del Formulario
  const [formularioState, setFormulario] = useState({
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    ine: "",
    curp: "",
    telefono: "",
    email: "",
    sexo: "",
    titulo: "",
    tipo_persona: "",
    edad: "",
    numero_ext: "",
    calle: "",
    colonia: "",
    municipio: "",
    localidad: "",
    estado: "",
    cp: "",
    cat_tipo_cargo_id: "",
    cat_tipo_titulo_id: "",
  });

  //state del modal de agregar nuevo usuario
  const [openModal, setopenModal] = useState(false);
  const [openModalEditarState, setopenModalEditar] = useState(false);
  const [openModalEliminar, setopenModalEliminar] = useState(false);

  //abrir y cerrar modal de eliminar usuario
  const openmodaleliminar = (data) => {
    setState({ ...state, ciudadanoSeleccionado: data });
    setopenModalEliminar(true);
  };

  const openModalNuevo = async () => {
    setLoader({ ...loader, loading: true });
    let municipios = await services({
      method: "GET",
      service: `catMunicipios/listar`,
      body: null,
    });
    let cargos = await services({
      method: "GET",
      service: `catTipoCargos/listarCargos`,
      body: null,
    });
    let titulos = await services({
      method: "GET",
      service: `catTiposTitulos/listar`,
      body: null,
    });

    if (
      municipios.status === 200 &&
      cargos.status === 200 &&
      titulos.status === 200
    ) {
      setLoader({ ...loader, loading: false });
      setState({ ...state, municipios: municipios.data.data });
      setCargos({ ...cargosState, data: cargos.data.data });
      setTitulos({ ...titulosState, data: titulos.data.data });
      setopenModal(true);
    }
  };

  const handleCloseRegistro = () => {
    setopenModal(false);
    setFormulario({ ...formularioState, municipio: "", localidad: "" });
    setErrors({});
  };

  const handleCloseEliminar = () => {
    // setState({
    //     ...state, openModalEliminar: false
    // });
    setopenModalEliminar(false);
  };

  const [errors, setErrors] = useState({});

  //abrir y cerrar modal de editar usuario
  const openModalEditar = async (data) => {
    //abrirEditar
    if (data.estado === "MICHOACÁN" && data.municipio_id !== null) {
      setLoader({ ...loader, loading: true });
      let localidades = await services({
        method: "POST",
        service: `catLocalidades/listaPorMunicipio`,
        body: { municipio_id: data.municipio_id },
      });
      let municipios = await services({
        method: "GET",
        service: `catMunicipios/listar`,
        body: null,
      });
      let cargos = await services({
        method: "GET",
        service: `catTipoCargos/listarCargos`,
        body: null,
      });
      let titulos = await services({
        method: "GET",
        service: `catTiposTitulos/listar`,
        body: null,
      });
      if (
        localidades.status === 200 &&
        cargos.status === 200 &&
        titulos.status === 200 &&
        municipios.status === 200
      ) {
        //
        setFormulario((formularioState) => ({
          ...formularioState,
          municipio: data ? data.municipio_id : "",
          localidad: "",
        }));
        //   setState({ ...state, municipios: municipios.data.data });
        setCargos({ ...cargosState, data: cargos.data.data });
        setTitulos({ ...titulosState, data: titulos.data.data });
        setLoader({ ...loader, loading: false });
      } else {
        setLoader({ ...loader, loading: false });
        showNotification("error", "Ups...", localidades.data.message, null, 4);
      }
      setState({
        ...state,
        municipios: municipios.data.data,
        ciudadanoSeleccionado: data,
        localidades: localidades.data.data,
      });
      setopenModalEditar(true);
      setLoader({ ...loader, loading: false });
    } else {
      setState({ ...state, ciudadanoSeleccionado: data });
      setopenModalEditar(true);
      setLoader({ ...loader, loading: false });
    }
  };

  const handleCloseEditar = () => {
    // setState({
    //     ...state, openModalEditar: false
    // });
    setopenModalEditar(false);
  };

  //columnas y lógica de la tabla
  const columns = [
    {
      name: "nombre",
      label: "NOMBRE",
      options: {
        filter: true,
        sort: true,
        display: false,
        download: true,
        customBodyRenderLite: (dataIndex) => {
          // let nombre = state.ciudadanos[dataIndex].nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          let nombre = state.ciudadanos[dataIndex].nombre;
          return (
            <div
              className="d-flex align-items-center"
              style={{ height: "60px" }}
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "nombre_completo",
      label: "NOMBRE",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let nombre = state.ciudadanos[dataIndex].nombre;
          let nombre_completo = state.ciudadanos[dataIndex].nombre_completo;

          return (
            <>
              <div
                className="d-flex align-items-center"
                style={{ height: "60px" }}
              >
                {nombre}
              </div>
              <div className="d-none">{nombre_completo}</div>
            </>
          );
        },
      },
    },
    {
      name: "apellido_paterno",
      label: "APELLIDO PATERNO",
      options: {
        filter: true,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex) => {
          let nombre = state.ciudadanos[dataIndex].apellido_paterno;
          return (
            <div
              className=" d-flex align-items-center"
              style={{ height: "60px" }}
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "apellido_materno",
      label: "APELLIDO MATERNO",
      options: {
        filter: true,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex) => {
          let nombre = state.ciudadanos[dataIndex].apellido_materno;
          return (
            <div
              className="d-flex align-items-center"
              style={{ height: "60px" }}
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "calle",
      label: "DIRECCIÓN",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let calle = state.ciudadanos[dataIndex].calle;
          if (calle !== null) {
            return (
              <div
                style={{ height: "60px" }}
                className="d-flex align-items-center"
              >
                {calle}
              </div>
            );
          } else {
            return (
              <div
                style={{ height: "60px" }}
                className="d-flex align-items-center justify-content-center"
              >
                -
              </div>
            );
          }
        },
      },
    },
    {
      name: "telefono",
      label: "TELÉFONO",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let telefono = state.ciudadanos[dataIndex].telefono;
          if (telefono !== null) {
            return (
              <div
                style={{ height: "60px" }}
                className="d-flex align-items-center"
              >
                {telefono}
              </div>
            );
          } else {
            return (
              <div
                style={{ height: "60px" }}
                className="d-flex align-items-center justify-content-center"
              >
                -
              </div>
            );
          }
        },
      },
    },
    {
      name: "municipio",
      label: "MUNICIPIO",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let municipio = state.ciudadanos[dataIndex].municipio;
          if (municipio !== null) {
            return (
              <div
                style={{ height: "60px" }}
                className="d-flex align-items-center"
              >
                {municipio}
              </div>
            );
          } else {
            return (
              <div
                style={{ height: "60px" }}
                className="d-flex align-items-center justify-content-center"
              >
                -
              </div>
            );
          }
        },
      },
    },
    {
      name: "acciones",
      label: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => (
          <>
            <div
              style={{ height: "60px" }}
              className="d-flex align-items-center"
            >
              <Tooltip title="VER SOLICITUDES ">
                <div
                  style={{ height: 30, width: 30, zIndex: 0 }}
                  className="cta cta--primary cta--icon icon_btn"
                  onClick={() => {
                    goTo(
                      `/solicitudCiudadano/${state.ciudadanos[dataIndex].id}`
                    );
                  }}
                >
                  <IconUI size={"20px"} color={"black"}>
                    <FaFileSignature />
                  </IconUI>
                </div>
              </Tooltip>

              <Tooltip title="VER PETICIONES">
                <div
                  style={{ height: 30, width: 30, zIndex: 0 }}
                  className="cta cta--primary cta--icon icon_btn"
                  onClick={() => {
                    goTo(
                      `/peticionesCiudadano/${state.ciudadanos[dataIndex].id}`
                    );
                  }}
                >
                  <IconUI size={"20px"} color={"black"}>
                    <FaHandsHelping />
                  </IconUI>
                </div>
              </Tooltip>
              <Tooltip title="EDITAR CIUDADANO">
                <div
                  style={{ height: 30, width: 30, zIndex: 0 }}
                  className="cta cta--primary cta--icon icon_btn"
                  onClick={() => {
                    openModalEditar(state.ciudadanos[dataIndex]);
                  }}
                >
                  <IconUI size={"20px"} color={"black"}>
                    <AiOutlineEdit />
                  </IconUI>
                </div>
              </Tooltip>
              <Tooltip title="ELIMINAR CIUDADANO">
                <div
                  style={{ height: 30, width: 30, zIndex: 0 }}
                  className="cta cta--primary cta--icon icon_btn"
                  onClick={() => {
                    openmodaleliminar(state.ciudadanos[dataIndex]);
                  }}
                >
                  <IconUI size={"20px"} color={"black"}>
                    <AiOutlineDelete />
                  </IconUI>
                </div>
              </Tooltip>
            </div>
          </>
        ),
      },
    },
  ];

  // manejo del formulario nuevo ciudadano
  const handleTitulo = (e) => {
    setFormulario({ ...formularioState, cat_tipo_titulo_id: e.target.value });
  };
  const handleNombre = (e) => {
    setFormulario({ ...formularioState, nombre: e.target.value.toUpperCase() });
  };
  const handleApellidoPaterno = (e) => {
    setFormulario({
      ...formularioState,
      apellido_paterno: e.target.value.toUpperCase(),
    });
  };
  const handleApellidoMaterno = (e) => {
    setFormulario({
      ...formularioState,
      apellido_materno: e.target.value.toUpperCase(),
    });
  };
  const handleTipo = (e) => {
    setFormulario({ ...formularioState, tipo_persona: e.target.value });
  };
  const handleSexo = (e) => {
    setFormulario({ ...formularioState, sexo: e.target.value });
  };
  const handleCurp = (e) => {
    setFormulario({ ...formularioState, curp: e.target.value });
  };
  const handleIne = (e) => {
    setFormulario({ ...formularioState, ine: e.target.value });
  };
  const handleCargo = (e) => {
    setFormulario((formularioState) => ({
      ...formularioState,
      cat_tipo_cargo_id: e ? e.id : null,
    }));
  };
  const handleTelefono = (e) => {
    setFormulario({ ...formularioState, telefono: e.target.value });
  };
  const handleEmail = (e) => {
    setFormulario({ ...formularioState, email: e.target.value.toUpperCase() });
  };
  const handleEdad = (e) => {
    setFormulario({ ...formularioState, edad: e.target.value });
  };
  // const handleFechaNacimiento = (e) => {
  //     const selectedDate = dayjs(e.$d);
  //     setFormulario({ ...formularioState, fecha_nacimiento: selectedDate.format('YYYY-MM-DD') })
  // }
  const handleCalle = (e) => {
    setFormulario({ ...formularioState, calle: e.target.value.toUpperCase() });
  };
  const handleNumero = (e) => {
    setFormulario({ ...formularioState, numero: e.target.value });
  };
  const handleColonia = (e) => {
    setFormulario({
      ...formularioState,
      colonia: e.target.value.toUpperCase(),
    });
  };
  const handleCp = (e) => {
    setFormulario({ ...formularioState, cp: e.target.value });
  };
  const handleEstado = (e) => {
    setFormulario({ ...formularioState, estado: e.target.value });
  };
  const handleMunicipio = async (e) => {
    if (e === null) {
      setFormulario((formularioState) => ({
        ...formularioState,
        municipio: "",
        localidad: "",
      }));
    } else {
      setLoader({ ...loader, loading: true });
      let localidades = await services({
        method: "POST",
        service: `catLocalidades/listaPorMunicipio`,
        body: { municipio_id: e.id },
      });
      if (localidades.status === 200) {
        setFormulario((formularioState) => ({
          ...formularioState,
          municipio: e ? e.id : "",
          localidad: "",
        }));
        setState({ ...state, localidades: localidades.data.data });
        setLoader({ ...loader, loading: false });
      } else {
        showNotification("error", "Ups...", localidades.data.message, null, 4);
        setLoader({ ...loader, loading: false });
      }
    }
  };
  const handleLocalidad = (e) => {
    setFormulario({ ...formularioState, localidad: e.target.value });
  };

  // manejo del formulario nuevo usuario

  // manejo de formulario editar usuario
  const handleSeleccionadoTitulo = (e) => {
    setState({
      ...state,
      ciudadanoSeleccionado: {
        ...state.ciudadanoSeleccionado,
        cat_tipo_titulo_id: e.target.value,
      },
    });
  };
  const handleSeleccionadoNombre = (e) => {
    setState({
      ...state,
      ciudadanoSeleccionado: {
        ...state.ciudadanoSeleccionado,
        nombre: e.target.value,
      },
    });
  };
  const handleSeleccionadoApellidoPaterno = (e) => {
    setState({
      ...state,
      ciudadanoSeleccionado: {
        ...state.ciudadanoSeleccionado,
        apellido_paterno: e.target.value,
      },
    });
  };
  const handleSeleccionadoApellidoMaterno = (e) => {
    setState({
      ...state,
      ciudadanoSeleccionado: {
        ...state.ciudadanoSeleccionado,
        apellido_materno: e.target.value,
      },
    });
  };
  const handleSeleccionadoTipo = (e) => {
    setState({
      ...state,
      ciudadanoSeleccionado: {
        ...state.ciudadanoSeleccionado,
        tipo_persona: e.target.value,
      },
    });
  };
  const handleSeleccionadoSexo = (e) => {
    setState({
      ...state,
      ciudadanoSeleccionado: {
        ...state.ciudadanoSeleccionado,
        sexo: e.target.value,
      },
    });
  };
  const handleSeleccionadoCurp = (e) => {
    setState({
      ...state,
      ciudadanoSeleccionado: {
        ...state.ciudadanoSeleccionado,
        curp: e.target.value,
      },
    });
  };
  const handleSeleccionadoIne = (e) => {
    setState({
      ...state,
      ciudadanoSeleccionado: {
        ...state.ciudadanoSeleccionado,
        ine: e.target.value,
      },
    });
  };
  const handleSeleccionadoCargo = (e) => {
    setState({
      ...state,
      ciudadanoSeleccionado: {
        ...state.ciudadanoSeleccionado,
        cat_tipo_cargo_id: e ? e.id : null,
      },
    });
  };
  const handleSeleccionadoTelefono = (e) => {
    setState({
      ...state,
      ciudadanoSeleccionado: {
        ...state.ciudadanoSeleccionado,
        telefono: e.target.value,
      },
    });
  };
  const handleSeleccionadoEmail = (e) => {
    setState({
      ...state,
      ciudadanoSeleccionado: {
        ...state.ciudadanoSeleccionado,
        email: e.target.value,
      },
    });
  };
  const handleSeleccionadoEdad = (e) => {
    setState({
      ...state,
      ciudadanoSeleccionado: {
        ...state.ciudadanoSeleccionado,
        edad: e.target.value,
      },
    });
  };
  // const handleSeleccionadoFechaNacimiento = (e) => {
  //     if (e === null) {
  //         setState({ ...state, ciudadanoSeleccionado: { ...state.ciudadanoSeleccionado, fecha_nacimiento: null } })
  //     } else {
  //         const selectedDate = dayjs(e);
  //         setState({ ...state, ciudadanoSeleccionado: { ...state.ciudadanoSeleccionado, fecha_nacimiento: selectedDate.format('YYYY-MM-DD') } })
  //     }
  // }
  const handleSeleccionadoCalle = (e) => {
    setState({
      ...state,
      ciudadanoSeleccionado: {
        ...state.ciudadanoSeleccionado,
        calle: e.target.value,
      },
    });
  };
  const handleSeleccionadoNumero = (e) => {
    setState({
      ...state,
      ciudadanoSeleccionado: {
        ...state.ciudadanoSeleccionado,
        numero_ext: e.target.value,
      },
    });
  };
  const handleSeleccionadoColonia = (e) => {
    setState({
      ...state,
      ciudadanoSeleccionado: {
        ...state.ciudadanoSeleccionado,
        colonia: e.target.value,
      },
    });
  };
  const handleSeleccionadoCp = (e) => {
    setState({
      ...state,
      ciudadanoSeleccionado: {
        ...state.ciudadanoSeleccionado,
        cp: e.target.value,
      },
    });
  };
  const handleSeleccionadoEstado = async (e) => {
    setLoader({ ...loader, loading: true });
    let municipios = await services({
      method: "GET",
      service: `catMunicipios/listar`,
      body: null,
    });
    if (municipios.status === 200) {
      setState({
        ...state,
        ciudadanoSeleccionado: {
          ...state.ciudadanoSeleccionado,
          estado: e.target.value,
        },
        municipios: municipios.data.data,
      });
      setLoader({ ...loader, loading: false });
    } else {
      showNotification("error", "Ups...", municipios.data.message, null, 4);
      setLoader({ ...loader, loading: false });
    }
  };

  const handleSeleccionadoMunicipio = async (e) => {
    if (e === null) {
      setState({
        ...state,
        localidades: [],
        ciudadanoSeleccionado: {
          ...state.ciudadanoSeleccionado,
          municipio_id: "",
        },
      });
    } else {
      setLoader({ ...loader, loading: true });
      let localidades = await services({
        method: "POST",
        service: `catLocalidades/listaPorMunicipio`,
        body: { municipio_id: e.id },
      });
      if (localidades.status === 200) {
        setState({
          ...state,
          localidades: localidades.data.data,
          ciudadanoSeleccionado: {
            ...state.ciudadanoSeleccionado,
            municipio_id: e ? e.id : null,
            localidad_id: null,
          },
        });
        setLoader({ ...loader, loading: false });
      } else {
        showNotification("error", "Ups...", localidades.data.message, null, 4);
        setLoader({ ...loader, loading: false });
      }
    }
  };
  const handleSeleccionadoLocalidad = async (e) => {
    setState({
      ...state,
      ciudadanoSeleccionado: {
        ...state.ciudadanoSeleccionado,
        localidad_id: e.target.value,
      },
    });
  };
  //manejo de formulario editar usuario

  // validaciones del formulario
  const validarFormulario = () => {
    let newErrors = {};
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const regexCurp =
      /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

    if (!formularioState.nombre) {
      newErrors.nombre = "Este Campo es Requerido";
    }
    if (!formularioState.apellido_paterno) {
      newErrors.apellidoPaterno = "Este Campo es Requerido";
    }
    if (formularioState.tipo_persona === "") {
      newErrors.tipo = "Este Campo es Requerido";
    }
    // if (formularioState.telefono !== "" && formularioState.telefono.length < 10) { newErrors.telefono = 'Favor poner un número de 10 dígitos' }
    if (formularioState.ine !== "" && formularioState.ine.length < 13) {
      newErrors.ine = "Favor poner un número de identificación válido";
    }
    if (formularioState.curp !== "" && formularioState.curp.length < 18) {
      let valido = formularioState.curp.match(regexCurp);
      if (!valido) {
        newErrors.curp = "Favor poner una curp válida";
      }
    }
    if (formularioState.cp !== "" && formularioState.cp.length < 5) {
      newErrors.cp = "Favor poner un código postal válido";
    }

    if (formularioState.email !== "") {
      let valido = formularioState.email.match(regexEmail);
      if (!valido) {
        newErrors.email = "Ingrese un email válido";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  // validaciones del editar
  const validarEditar = () => {
    let newErrors = {};
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const regexCurp =
      /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

    if (!state.ciudadanoSeleccionado.nombre) {
      newErrors.nombre = "Este Campo es Requerido";
    }
    if (!state.ciudadanoSeleccionado.apellido_paterno) {
      newErrors.apellidoPaterno = "Este Campo es Requerido";
    }
    // if (!state.ciudadanoSeleccionado.apellido_materno) { newErrors.apellidoMaterno = 'Este Campo es Requerido' }
    if (state.ciudadanoSeleccionado.tipo_persona === "") {
      newErrors.tipo = "Este Campo es Requerido";
    }
    if (
      state.ciudadanoSeleccionado.ine !== null &&
      state.ciudadanoSeleccionado.ine !== "" &&
      state.ciudadanoSeleccionado.ine.length < 13
    ) {
      newErrors.ine = "Favor poner un número de identificación válido";
    }
    if (
      state.ciudadanoSeleccionado.curp !== null &&
      state.ciudadanoSeleccionado.curp !== "" &&
      state.ciudadanoSeleccionado.curp.length < 18
    ) {
      let valido = state.ciudadanoSeleccionado.curp.match(regexCurp);
      if (!valido) {
        newErrors.curp = "Favor poner una curp válida";
      }
    }
    // if (state.ciudadanoSeleccionado.telefono !== null && state.ciudadanoSeleccionado.telefono.length < 10) { newErrors.telefono = 'Favor poner un número de 10 dígitos' }
    if (
      state.ciudadanoSeleccionado.cp !== null &&
      state.ciudadanoSeleccionado.cp.length < 5
    ) {
      newErrors.cp = "Favor poner un código postal válido";
    }

    if (
      state.ciudadanoSeleccionado.email !== null &&
      state.ciudadanoSeleccionado.email !== ""
    ) {
      let valido = state.ciudadanoSeleccionado.email.match(regexEmail);
      if (!valido) {
        newErrors.email = "Ingrese un email válido";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [totalCiudadanos, setTotalCiudadanos] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //Petición de ciudadanos
  const mostrarCiudadanos = async (pageIndex = 0, rowsPerPage = 10) => {
    if (buscar) {
      setBuscar(false);
      pageIndex = 0;
      setPage(0);
      setBuscador({ ...buscador, error: "" });
    }
    setLoader({ ...loader, loading: true });

    let ciudadanos = await services({
      method: "GET",
      service: `ciudadanos/listar/${rowsPerPage}?page=${pageIndex + 1}`,
      body: null,
    });

    if (ciudadanos.status === 200) {
      setState({ ...state, ciudadanos: ciudadanos.data.data.data });
      setTotalCiudadanos(ciudadanos.data.data.total);
      setLoader({ ...loader, loading: false });
    } else {
      setLoader({ ...loader, loading: false });
      showNotification(
        "error",
        "ups... Algo sucedió",
        "La información no fue cargada correctamente",
        null,
        4
      );
    }
  };

  const [buscar, setBuscar] = useState(false);
  const [buscador, setBuscador] = useState({
    texto: "",
    error: "",
  });

  const handleChangeBuscador = (e) => {
    setBuscador({ ...buscador, texto: e.target.value });
  };

  const validarBuscador = async (pageIndex = 0) => {
    if (!buscador.texto) {
      const pagOriginal = 0;
      mostrarCiudadanos(pagOriginal);
      setBuscador({ ...buscador, error: "El campo no puede ir vacío" });
      setTimeout(() => {
        setBuscador({ ...buscador, error: "" });
      }, 3000);
    } else {
      setLoader({ ...loader, loading: true });
      const body = {
        buscador: buscador.texto,
        cantidad: 10,
      };
      const request = await services({
        method: "POST",
        service: `ciudadanos/buscarCiudadano?page=${pageIndex + 1}`,
        body,
      });

      if (request.status === 200) {
        setBuscar(true);
        setBuscador({ ...buscador, error: "" });
        setState({ ...state, ciudadanos: request.data.data.data });
        setTotalCiudadanos(request.data.data.total);
        setLoader({ ...loader, loading: false });
      } else if (request.status === 500) {
        setLoader({ ...loader, loading: false });
        setBuscador({
          ...buscador,
          error: "Ha ocurrido un error en el servidor",
        });
      } else {
        setLoader({ ...loader, loading: false });
        setBuscador({ ...buscador, error: request.data.message });
      }
    }
  };

  const exportar = async () => {
    setLoader({ ...loader, loading: true });

    let response = await services(
      {
        method: "POST",
        service: `ciudadanos/exportar`,
        body: { buscador: buscador.texto },
      },
      {
        responseType: "arraybuffer",
      }
    );
    if (response.status === 200) {
      setLoader({ ...loader, loading: false });
      showNotification(
        "success",
        "Éxito",
        "Se ha descargado su archivo",
        null,
        4
      );
      const link = document.createElement("a");
      link.href = `data:application/octet-stream;base64,${response.data.archivo}`;
      link.download = "Ciudadanos.xlsx";
      link.click();
    } else {
      setLoader({ ...loader, loading: false });
      showNotification(
        "error",
        "Ups...",
        "Ocurrió algo al descargar su archivo",
        null,
        4
      );
    }
  };

  const customOptions = {
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    serverSide: true,
    count: totalCiudadanos,
    page,
    rowsPerPage,
    onTableChange: async (action, tableState) => {
      if (action === "changePage") {
        setPage(tableState.page);
      }
      if (action === "changeRowsPerPage") {
        setRowsPerPage(tableState.rowsPerPage);
        setPage(0);
      }
    },
    rowsPerPageOptions: [],
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: "Lo sentimos, no hay coincidencias en la búsqueda",
        toolTip: "Clasificar",
        columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
      },
    },
  };

  //limpiar formulario
  const limpiarFormulario = () => {
    setFormulario({
      ...formularioState,
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      ine: "",
      telefono: "",
      email: "",
      sexo: "",
      titulo: "",
      tipo_persona: "",
      fecha_nacimiento: "",
      numero_ext: "",
      calle: "",
      colonia: "",
      municipio: "",
      localidad: "",
      estado: "",
      cp: "",
      cat_tipo_cargo_id: "",
      cat_tipo_titulo_id: "",
    });
  };

  //Crear Ciudadano Nuevo
  const crearCiudadano = async () => {
    if (validarFormulario()) {
      setLoader({ ...loader, loading: true });
      const ciudadano = await services({
        method: "POST",
        service: "ciudadanos/crear",
        body: {
          cat_tipo_titulo_id: formularioState.cat_tipo_titulo_id,
          nombre: formularioState.nombre,
          apellido_paterno: formularioState.apellido_paterno,
          apellido_materno: formularioState.apellido_materno,
          tipo_persona: formularioState.tipo_persona,
          sexo: formularioState.sexo,
          ine: formularioState.ine,
          cat_tipo_cargo_id: formularioState.cat_tipo_cargo_id,
          telefono: formularioState.telefono,
          email: formularioState.email,
          edad: formularioState.edad,
          calle: formularioState.calle,
          numero_ext: formularioState.numero,
          colonia: formularioState.colonia,
          cp: formularioState.cp,
          estado: formularioState.estado,
          municipio_id: formularioState.municipio,
          localidad_id: formularioState.localidad,
        },
      });
      if (ciudadano.data.status === "success") {
        // let ciudadanos = await services({ method: 'GET', service: `ciudadanos/listar/0`, body: null })
        // if (ciudadanos) {
        //     setState({ ...state, ciudadanos: ciudadanos.data.data })
        setopenModal(false);
        showNotification("success", "Ciudadano", "Agregado con Éxito", null, 4);
        mostrarCiudadanos(page);
        limpiarFormulario();
        setLoader({ ...loader, loading: false });

        // }
      } else {
        showNotification(
          "error",
          "Ups... Algo sucedió",
          ciudadano.data.message,
          null,
          4
        );
        setopenModal(false);
        setLoader({ ...loader, loading: false });
        limpiarFormulario();
      }
    } else {
      showNotification(
        "error",
        "Ups...Error ",
        "Por favor llena todos los campos",
        null,
        4
      );
      setLoader({ ...loader, loading: false });
    }
  };

  // editar Usuario Nuevo
  const editarCiudadano = async () => {
    setLoader({ ...loader, loading: true });
    if (validarEditar()) {
      const ciudadano = await services({
        method: "POST",
        service: `ciudadanos/editar`,
        body: {
          id: state.ciudadanoSeleccionado.id,
          cat_tipo_titulo_id: state.ciudadanoSeleccionado.cat_tipo_titulo_id,
          nombre: state.ciudadanoSeleccionado.nombre?.toUpperCase(),
          apellido_paterno:
            state.ciudadanoSeleccionado.apellido_paterno?.toUpperCase(),
          apellido_materno:
            state.ciudadanoSeleccionado.apellido_materno?.toUpperCase(),
          curp: state.ciudadanoSeleccionado.curp,
          tipo_persona: state.ciudadanoSeleccionado.tipo_persona,
          edad: state.ciudadanoSeleccionado.edad,
          sexo: state.ciudadanoSeleccionado.sexo,
          ine: state.ciudadanoSeleccionado.ine?.toUpperCase(),
          cat_tipo_cargo_id: state.ciudadanoSeleccionado.cat_tipo_cargo_id,
          telefono: state.ciudadanoSeleccionado.telefono,
          email: state.ciudadanoSeleccionado.email?.toUpperCase(),
          fecha_nacimiento: state.ciudadanoSeleccionado.fecha_nacimiento,
          calle: state.ciudadanoSeleccionado.calle?.toUpperCase(),
          numero_ext: state.ciudadanoSeleccionado.numero_ext?.toUpperCase(),
          colonia: state.ciudadanoSeleccionado.colonia?.toUpperCase(),
          cp: state.ciudadanoSeleccionado.cp,
          estado: state.ciudadanoSeleccionado.estado,
          municipio_id: state.ciudadanoSeleccionado.municipio_id,
          localidad_id: state.ciudadanoSeleccionado.localidad_id,
        },
      });
      if (ciudadano.status === 200) {
        // let ciudadanos = await services({ method: 'GET', service: `ciudadanos/listar/0`, body: null })
        // if (ciudadanos.status === 200) {
        // setState({ ...state, ciudadanos: ciudadanos.data.data, openModalEditar: false })
        setopenModalEditar(false);
        showNotification("success", "Ciudadano", "Editado con Éxito", null, 4);
        mostrarCiudadanos(page);
        setLoader({ ...loader, loading: false });
      } else {
        setLoader({ ...loader, loading: false });
        showNotification(
          "error",
          "Ups... Algo Sucedió",
          "Error al Editar Ciudadano",
          null,
          4
        );
        // setState({ ...state, openModalEditar: false })
        setopenModalEditar(false);
        // }
      }
      // else {
      //     setLoader({ ...loader, loading: false })
      //     showNotification('error', 'Ups... Algo Sucedió', ciudadano.data.message, null, 4);
      //     setState({ ...state, openModalEditar: false })
      // }
    } else {
      setLoader({ ...loader, loading: false });
      showNotification("error", "Verifica", "Los campos marcados", null, 4);
    }
  };

  // eliminarCiudadano
  const eliminarCiudadano = async () => {
    setLoader({ ...loader, loading: true });
    const ciudadano = await services({
      method: "POST",
      service: `ciudadanos/eliminar`,
      body: { id: state.ciudadanoSeleccionado.id },
    });
    if (ciudadano.status === 200) {
      // let ciudadanos = await services({ method: 'GET', service: `ciudadanos/listar/0`, body: null })
      // if (ciudadanos) {
      //     setState({ ...state, ciudadanos: ciudadanos.data.data, openModalEliminar: false })
      setopenModalEliminar(false);
      mostrarCiudadanos(page);
      showNotification("success", "Ciudadano", "Eliminado con Éxito", null, 4);
      setLoader({ ...loader, loading: false });
    } else {
      // setState({ ...state, openModalEliminar: false })
      setopenModalEliminar(false);
      setLoader({ ...loader, loading: false });
      showNotification(
        "error",
        "ups... Algo sucedió",
        ciudadano.data.message,
        null,
        4
      );
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (!buscar) {
        mostrarCiudadanos(page);
      } else {
        validarBuscador(page);
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [page, pageSize]);

  return (
    <>
      <div className=" justify-content-center align-items-start py-4">
        {loader.loading && <LoaderComponent />}
        <div className="container">
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                sx={{
                  borderRadius: "30px",
                  backgroundColor: "#fff",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
                fullWidth
                size="small"
                id="busqueda"
                type="text"
                label="Buscar..."
                variant="outlined"
                value={buscador.texto}
                error={!!buscador.error}
                helperText={buscador.error || ""}
                onChange={handleChangeBuscador}
                InputProps={{
                  maxLength: 100,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setPage(0);
                          validarBuscador();
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>
              <ColorButton
                startIcon={<RiFileExcel2Line />}
                className="me-3"
                onClick={exportar}
              >
                exportar
              </ColorButton>
              <ColorButton
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => {
                  openModalNuevo();
                }}
              >
                NUEVO REGISTRO
              </ColorButton>
            </Grid>
          </Grid>
        </div>
        <Box sx={{ flexGrow: 2 }}>
          <Grid className="container mt-3 mb-3" maxWidth="xl">
            <ThemeProvider theme={getMuiTheme}>
              <MUIDataTable
                data={state.ciudadanos}
                title={`TABLA DE CIUDADANOS`}
                columns={columns}
                options={customOptions}
              />
            </ThemeProvider>
          </Grid>
        </Box>
        {/* Modal para crear ciudadano */}
        <Modal
          open={openModal}
          onClose={handleCloseRegistro}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="contenedorModalUrs" sx={style}>
            <h3 className="TitulosProgramas">NUEVO CIUDADANO</h3>
            <hr />
            <h5>DATOS GENERALES</h5>
            <hr />
            <Grid container columns={12} spacing={3}>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-Titulo"
                  label="TÍTULO"
                  variant="outlined"
                  select
                  error={!!errors.titulo}
                  helperText={errors.titulo || ""}
                  onChange={handleTitulo}
                  value={formularioState.cat_tipo_titulo_id}
                >
                  {titulosState.data &&
                    titulosState.data.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.tipo_titulo}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="NOMBRE"
                  variant="outlined"
                  error={!!errors.nombre}
                  helperText={errors.nombre || ""}
                  value={formularioState.nombre}
                  onChange={handleNombre}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-apellidoP"
                  label="APELLIDO PATERNO"
                  variant="outlined"
                  error={!!errors.apellidoPaterno}
                  helperText={errors.apellidoPaterno || ""}
                  value={formularioState.apellido_paterno}
                  onChange={handleApellidoPaterno}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-apellidoM"
                  label=" APELLIDO MATERNO"
                  variant="outlined"
                  error={!!errors.apellidoMaterno}
                  helperText={errors.apellidoMaterno || ""}
                  value={formularioState.apellido_materno}
                  onChange={handleApellidoMaterno}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-TipoUsuario"
                  label="TIPO"
                  variant="outlined"
                  select
                  error={!!errors.tipo}
                  helperText={errors.tipo || ""}
                  onChange={handleTipo}
                  value={formularioState.tipo_persona}
                >
                  <MenuItem value={1}>CIUDADANO</MenuItem>
                  <MenuItem value={0}>ASOCIACIÓN CIVIL</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-INE"
                  label="EDAD"
                  variant="outlined"
                  error={!!errors.edad}
                  helperText={errors.edad || ""}
                  onChange={handleEdad}
                  value={formularioState.edad}
                  inputProps={{ maxLength: 2 }}
                  onKeyPress={onlyNumber}
                />
              </Grid>
              {/* <Grid item xs={12}>
                                                    <LocalizationProvider adapterLocale='es' dateAdapter={AdapterDayjs} >
                                                        <DatePicker
                                                            label="FECHA DE NACIMIENTO"
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            sx={{ width: 1 }}
                                                            // slotProps={{ textField: { helperText: 'Please fill this field' } }}
                                                            // defaultValue={today}
                                                            format="DD/MM/YYYY"
                                                            views={['year', 'month', 'day']}
                                                            onChange={handleFechaNacimiento}
                                                            disableFuture

                                                        />
                                                    </LocalizationProvider>
                                                </Grid> */}
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-TipoUsuario"
                  label="GÉNERO"
                  variant="outlined"
                  select
                  error={!!errors.genero}
                  helperText={errors.genero || ""}
                  onChange={handleSexo}
                  value={formularioState.sexo}
                >
                  <MenuItem value={"MASCULINO"}>MASCULINO</MenuItem>
                  <MenuItem value={"FEMENINO"}>FEMENINO</MenuItem>
                  <MenuItem value={"LGTBIQA+"}>LGTBIQA+</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-CURP"
                  label="CURP"
                  variant="outlined"
                  error={!!errors.curp}
                  helperText={errors.curp || ""}
                  onChange={handleCurp}
                  value={formularioState.curp}
                  // onKeyPress={onlyNumber}
                  inputProps={{ maxLength: 18 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-INE"
                  label="INE"
                  variant="outlined"
                  error={!!errors.ine}
                  helperText={errors.ine || ""}
                  onChange={handleIne}
                  value={formularioState.ine}
                  onKeyPress={onlyNumber}
                  inputProps={{ maxLength: 13 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={cargosState.data || []}
                  getOptionLabel={(option) => option.tipo_cargo}
                  fullWidth
                  size="small"
                  id="textFile-area"
                  sx={{
                    borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                  }}
                  value={
                    cargosState.data.find(
                      (option) =>
                        option.id === formularioState.cat_tipo_cargo_id
                    ) || null
                  }
                  onChange={(event, newValue) => handleCargo(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="CARGO"
                      error={!!errors.cargo}
                      helperText={errors.cargo || ""}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-telefono"
                  label="TELÉFONO"
                  variant="outlined"
                  error={!!errors.telefono}
                  helperText={errors.telefono || ""}
                  onChange={handleTelefono}
                  value={formularioState.telefono}
                  // onKeyPress={onlyNumber}
                  inputProps={{ maxLength: 260 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-correo"
                  label="CORREO"
                  variant="outlined"
                  value={formularioState.email}
                  error={errors.email}
                  helperText={errors.email || ""}
                  onChange={handleEmail}
                />
              </Grid>
              <hr />
              <hr />
              <h5 className="ms-4 mt-3">DIRECCIÓN</h5>
              <hr />
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-calle"
                  label="CALLE"
                  variant="outlined"
                  error={errors.calle}
                  helperText={errors.calle || ""}
                  value={formularioState.calle}
                  onChange={handleCalle}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-numero"
                  label="NÚMERO"
                  variant="outlined"
                  error={errors.numero}
                  helperText={errors.numero || ""}
                  value={formularioState.numero}
                  onChange={handleNumero}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-colonia"
                  label="COLONIA"
                  variant="outlined"
                  error={errors.colonia}
                  helperText={errors.colonia || ""}
                  value={formularioState.colonia}
                  onChange={handleColonia}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-cp"
                  label="C.P."
                  variant="outlined"
                  error={errors.cp}
                  helperText={errors.cp || ""}
                  value={formularioState.cp}
                  onChange={handleCp}
                  inputProps={{ maxLength: 5 }}
                  onKeyPress={onlyNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-localidad"
                  label="ENTIDAD FEDERATIVA"
                  variant="outlined"
                  error={errors.estado}
                  helperText={errors.estado || ""}
                  value={formularioState.estado}
                  onChange={handleEstado}
                  select
                >
                  <MenuItem value={"AGUASCALIENTES"}>AGUASCALIENTES</MenuItem>
                  <MenuItem value={"BAJA CALIFORNIA"}>BAJA CALIFORNIA</MenuItem>
                  <MenuItem value={"BAJA CALIFORNIA SUR"}>
                    BAJA CALIFORNIA SUR
                  </MenuItem>
                  <MenuItem value={"CAMPECHE"}>CAMPECHE</MenuItem>
                  <MenuItem value={"CHIAPAS"}>CHIAPAS</MenuItem>
                  <MenuItem value={"CHIHUAHUA"}>CHIHUAHUA</MenuItem>
                  <MenuItem value={"CIUDAD DE MÉXICO"}>
                    CIUDAD DE MÉXICO
                  </MenuItem>
                  <MenuItem value={"COAHUILA DE ZARAGOZA"}>
                    COAHUILA DE ZARAGOZA
                  </MenuItem>
                  <MenuItem value={"COLIMA"}>COLIMA</MenuItem>
                  <MenuItem value={"DURANGO"}>DURANGO</MenuItem>
                  <MenuItem value={"GUANAJUATO"}>GUANAJUATO</MenuItem>
                  <MenuItem value={"GUERRERO"}>GUERRERO</MenuItem>
                  <MenuItem value={"HIDALGO"}>HIDALGO</MenuItem>
                  <MenuItem value={"JALISCO"}>JALISCO</MenuItem>
                  <MenuItem value={"ESTADO DE MÉXICO"}>
                    ESTADO DE MÉXICO
                  </MenuItem>
                  <MenuItem value={"MICHOACÁN"}>MICHOACÁN</MenuItem>
                  <MenuItem value={"MORELOS"}>MORELOS</MenuItem>
                  <MenuItem value={"NAYARIT"}>NAYARIT</MenuItem>
                  <MenuItem value={"NUEVO LEÓN"}>NUEVO LEÓN</MenuItem>
                  <MenuItem value={"OAXACA"}>OAXACA</MenuItem>
                  <MenuItem value={"PUEBLA"}>PUEBLA</MenuItem>
                  <MenuItem value={"QUERÉTARO"}>QUERÉTARO</MenuItem>
                  <MenuItem value={"QUINTANA ROO"}>QUINTANA ROO</MenuItem>
                  <MenuItem value={"SAN LUIS POTOSÍ"}>SAN LUIS POTOSÍ</MenuItem>
                  <MenuItem value={"SINALOA"}>SINALOA</MenuItem>
                  <MenuItem value={"SONORA"}>SONORA</MenuItem>
                  <MenuItem value={"TABASCO"}>TABASCO</MenuItem>
                  <MenuItem value={"TAMAULIPAS"}>TAMAULIPAS</MenuItem>
                  <MenuItem value={"TLAXCALA"}>TLAXCALA</MenuItem>
                  <MenuItem value={"VERACRUZ"}>VERACRUZ</MenuItem>
                  <MenuItem value={"YUCATÁN"}>YUCATÁN</MenuItem>
                  <MenuItem value={"ZACATECAS"}>ZACATECAS</MenuItem>
                </TextField>
              </Grid>
              {formularioState.estado !== "MICHOACÁN" ? (
                <></>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Autocomplete //CREAR!
                      options={state.municipios || []}
                      getOptionLabel={(option) => option.municipio}
                      fullWidth
                      size="small"
                      id="textFile-area"
                      sx={{
                        borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                      }}
                      value={
                        state.municipios.find(
                          (option) => option.id === formularioState.municipio
                        ) || null
                      }
                      onChange={(event, newValue) => handleMunicipio(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="MUNICIPIO"
                          error={!!errors.municipio}
                          helperText={errors.municipio || ""}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      sx={{ borderRadius: "30px" }}
                      fullWidth
                      size="small"
                      id="textFile-Titulo"
                      label="LOCALIDAD"
                      variant="outlined"
                      select
                      error={!!errors.localidad}
                      helperText={errors.localidad || ""}
                      onChange={handleLocalidad}
                      value={formularioState.localidad}
                    >
                      {state.localidades.length > 0 ? (
                        state.localidades.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.localidad}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key={0} value={null}>
                          SIN LOCALIDADES
                        </MenuItem>
                      )}
                    </TextField>
                  </Grid>
                </>
              )}

              <Grid marginLeft="auto" item>
                <ColorButton
                  onClick={() => {
                    crearCiudadano();
                  }}
                >
                  GUARDAR
                </ColorButton>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        {/* Modal para editar ciudadano */}
        <Modal
          open={openModalEditarState}
          onClose={handleCloseEditar}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="contenedorModalUrs" sx={style}>
            <h3 className="TitulosProgramas">EDITAR CIUDADANO</h3>
            <hr />
            <h5>DATOS GENERALES</h5>
            <hr />
            <Grid container columns={12} spacing={3}>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-Titulo"
                  label="TÍTULO"
                  variant="outlined"
                  select
                  error={!!errors.titulo}
                  helperText={errors.titulo || ""}
                  onChange={handleSeleccionadoTitulo}
                  value={state.ciudadanoSeleccionado.cat_tipo_titulo_id}
                >
                  {titulosState.data &&
                    titulosState.data.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.tipo_titulo}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="NOMBRE"
                  variant="outlined"
                  error={!!errors.nombre}
                  helperText={errors.nombre || ""}
                  value={state.ciudadanoSeleccionado.nombre}
                  onChange={handleSeleccionadoNombre}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-apellidoP"
                  label="APELLIDO PATERNO"
                  variant="outlined"
                  error={!!errors.apellidoPaterno}
                  helperText={errors.apellidoPaterno || ""}
                  value={state.ciudadanoSeleccionado.apellido_paterno}
                  onChange={handleSeleccionadoApellidoPaterno}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-apellidoM"
                  label="APELLIDO MATERNO"
                  variant="outlined"
                  error={!!errors.apellidoMaterno}
                  helperText={errors.apellidoMaterno || ""}
                  value={state.ciudadanoSeleccionado.apellido_materno}
                  onChange={handleSeleccionadoApellidoMaterno}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-TipoUsuario"
                  label="TIPO"
                  variant="outlined"
                  select
                  error={!!errors.tipo}
                  helperText={errors.tipo || ""}
                  onChange={handleSeleccionadoTipo}
                  value={state.ciudadanoSeleccionado.tipo_persona}
                >
                  <MenuItem value={1}>CIUDADANO</MenuItem>
                  <MenuItem value={0}>ASOCIACIÓN CIVIL</MenuItem>
                </TextField>
              </Grid>
              {/* <Grid item xs={12}>
                                                    <LocalizationProvider adapterLocale='es' dateAdapter={AdapterDayjs} >
                                                        <DatePicker
                                                            label="FECHA DE NACIMIENTO"
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            sx={{ width: 1 }}
                                                            value={state.ciudadanoSeleccionado.fecha_nacimiento ? dayjs(state.ciudadanoSeleccionado.fecha_nacimiento) : null}
                                                            format="DD/MM/YYYY"
                                                            views={['year', 'month', 'day']}
                                                            onChange={handleSeleccionadoFechaNacimiento}
                                                            disableFuture
                                                        />
                                                    </LocalizationProvider>
                                                </Grid> */}
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-INE"
                  label="EDAD"
                  variant="outlined"
                  error={!!errors.edad}
                  helperText={errors.edad || ""}
                  onChange={handleSeleccionadoEdad}
                  value={state.ciudadanoSeleccionado.edad}
                  inputProps={{ maxLength: 2 }}
                  onKeyPress={onlyNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-TipoUsuario"
                  label="GÉNERO"
                  variant="outlined"
                  select
                  error={!!errors.genero}
                  helperText={errors.genero || ""}
                  onChange={handleSeleccionadoSexo}
                  value={state.ciudadanoSeleccionado.sexo}
                >
                  <MenuItem value={"MASCULINO"}>MASCULINO</MenuItem>
                  <MenuItem value={"FEMENINO"}>FEMENINO</MenuItem>
                  <MenuItem value={"LGTBIQA+"}>LGTBIQA+</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-CURP"
                  label="CURP"
                  variant="outlined"
                  error={!!errors.curp}
                  helperText={errors.curp || ""}
                  onChange={handleSeleccionadoCurp}
                  value={state.ciudadanoSeleccionado.curp}
                  inputProps={{ maxLength: 18 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-INE"
                  label="INE"
                  variant="outlined"
                  error={!!errors.ine}
                  helperText={errors.ine || ""}
                  onChange={handleSeleccionadoIne}
                  value={state.ciudadanoSeleccionado.ine}
                  inputProps={{ maxLength: 13 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={cargosState.data || []}
                  getOptionLabel={(option) => option.tipo_cargo}
                  fullWidth
                  size="small"
                  id="textFile-area"
                  sx={{
                    borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                  }}
                  value={
                    cargosState.data.find(
                      (option) =>
                        option.id ===
                        state.ciudadanoSeleccionado.cat_tipo_cargo_id
                    ) || null
                  }
                  onChange={(event, newValue) =>
                    handleSeleccionadoCargo(newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="CARGO"
                      error={!!errors.cargo}
                      helperText={errors.cargo || ""}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-telefono"
                  label="TELÉFONO"
                  variant="outlined"
                  error={!!errors.telefono}
                  helperText={errors.telefono || ""}
                  onChange={handleSeleccionadoTelefono}
                  value={
                    state.ciudadanoSeleccionado.telefono !== null
                      ? state.ciudadanoSeleccionado.telefono
                      : ""
                  }
                  // onKeyPress={onlyNumber}
                  inputProps={{ maxLength: 260 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-correo"
                  label="CORREO"
                  variant="outlined"
                  value={
                    state.ciudadanoSeleccionado.email !== null
                      ? state.ciudadanoSeleccionado.email
                      : ""
                  }
                  error={errors.email}
                  helperText={errors.email || ""}
                  onChange={handleSeleccionadoEmail}
                />
              </Grid>
              <hr />
              <hr />
              <h5 className="ms-4 mt-3">DIRECCIÓN</h5>
              <hr />
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-calle"
                  label="CALLE"
                  variant="outlined"
                  error={errors.calle}
                  helperText={errors.calle || ""}
                  value={state.ciudadanoSeleccionado.calle}
                  onChange={handleSeleccionadoCalle}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-numero"
                  label="NÚMERO"
                  variant="outlined"
                  error={errors.numero}
                  helperText={errors.numero || ""}
                  value={state.ciudadanoSeleccionado.numero_ext}
                  onChange={handleSeleccionadoNumero}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-colonia"
                  label="COLONIA"
                  variant="outlined"
                  error={errors.colonia}
                  helperText={errors.colonia || ""}
                  value={state.ciudadanoSeleccionado.colonia}
                  onChange={handleSeleccionadoColonia}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-cp"
                  label="C.P."
                  variant="outlined"
                  error={errors.cp}
                  helperText={errors.cp || ""}
                  value={state.ciudadanoSeleccionado.cp}
                  onChange={handleSeleccionadoCp}
                  inputProps={{ maxLength: 5 }}
                  onKeyPress={onlyNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-localidad"
                  label="ENTIDAD FEDERATIVA"
                  variant="outlined"
                  select
                  error={errors.estado}
                  helperText={errors.estado || ""}
                  value={state.ciudadanoSeleccionado.estado}
                  onChange={handleSeleccionadoEstado}
                >
                  <MenuItem value={"AGUASCALIENTES"}>AGUASCALIENTES</MenuItem>
                  <MenuItem value={"BAJA CALIFORNIA"}>BAJA CALIFORNIA</MenuItem>
                  <MenuItem value={"BAJA CALIFORNIA SUR"}>
                    BAJA CALIFORNIA SUR
                  </MenuItem>
                  <MenuItem value={"CAMPECHE"}>CAMPECHE</MenuItem>
                  <MenuItem value={"CHIAPAS"}>CHIAPAS</MenuItem>
                  <MenuItem value={"CHIHUAHUA"}>CHIHUAHUA</MenuItem>
                  <MenuItem value={"CIUDAD DE MÉXICO"}>
                    CIUDAD DE MÉXICO
                  </MenuItem>
                  <MenuItem value={"COAHUILA DE ZARAGOZA"}>
                    COAHUILA DE ZARAGOZA
                  </MenuItem>
                  <MenuItem value={"COLIMA"}>COLIMA</MenuItem>
                  <MenuItem value={"DURANGO"}>DURANGO</MenuItem>
                  <MenuItem value={"GUANAJUATO"}>GUANAJUATO</MenuItem>
                  <MenuItem value={"GUERRERO"}>GUERRERO</MenuItem>
                  <MenuItem value={"HIDALGO"}>HIDALGO</MenuItem>
                  <MenuItem value={"JALISCO"}>JALISCO</MenuItem>
                  <MenuItem value={"ESTADO DE MÉXICO"}>
                    ESTADO DE MÉXICO
                  </MenuItem>
                  <MenuItem value={"MICHOACÁN"}>MICHOACÁN</MenuItem>
                  <MenuItem value={"MORELOS"}>MORELOS</MenuItem>
                  <MenuItem value={"NAYARIT"}>NAYARIT</MenuItem>
                  <MenuItem value={"NUEVO LEÓN"}>NUEVO LEÓN</MenuItem>
                  <MenuItem value={"OAXACA"}>OAXACA</MenuItem>
                  <MenuItem value={"PUEBLA"}>PUEBLA</MenuItem>
                  <MenuItem value={"QUERÉTARO"}>QUERÉTARO</MenuItem>
                  <MenuItem value={"QUINTANA ROO"}>QUINTANA ROO</MenuItem>
                  <MenuItem value={"SAN LUIS POTOSÍ"}>SAN LUIS POTOSÍ</MenuItem>
                  <MenuItem value={"SINALOA"}>SINALOA</MenuItem>
                  <MenuItem value={"SONORA"}>SONORA</MenuItem>
                  <MenuItem value={"TABASCO"}>TABASCO</MenuItem>
                  <MenuItem value={"TAMAULIPAS"}>TAMAULIPAS</MenuItem>
                  <MenuItem value={"TLAXCALA"}>TLAXCALA</MenuItem>
                  <MenuItem value={"VERACRUZ"}>VERACRUZ</MenuItem>
                  <MenuItem value={"YUCATÁN"}>YUCATÁN</MenuItem>
                  <MenuItem value={"ZACATECAS"}>ZACATECAS</MenuItem>
                </TextField>
              </Grid>
              {state.ciudadanoSeleccionado.estado !== "MICHOACÁN" ? (
                <></>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Autocomplete //EDITAR!
                      options={state.municipios || []}
                      getOptionLabel={(option) => option.municipio}
                      fullWidth
                      size="small"
                      id="textFile-area"
                      sx={{
                        borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                      }}
                      value={
                        state.municipios.find(
                          (option) =>
                            option.id ===
                            state.ciudadanoSeleccionado.municipio_id
                        ) || null
                      }
                      onChange={(event, newValue) =>
                        handleSeleccionadoMunicipio(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="MUNICIPIO"
                          error={!!errors.municipio}
                          helperText={errors.municipio || ""}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      sx={{ borderRadius: "30px" }}
                      fullWidth
                      size="small"
                      id="textFile-Titulo"
                      label="LOCALIDAD" //aqui
                      variant="outlined"
                      select
                      error={!!errors.localidad}
                      helperText={errors.localidad || ""}
                      onChange={handleSeleccionadoLocalidad}
                      value={state.ciudadanoSeleccionado.localidad_id}
                      // onChange={(event, newValue) => handleSeleccionadoLocalidad(newValue)}
                      // value={state.municipios.find(option => option.id === state.ciudadanoSeleccionado.municipio_id) || null}
                    >
                      {state.localidades.length > 0 ||
                      state.localidades !== undefined ? (
                        state.localidades.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.localidad}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key={0} value={null}>
                          SIN LOCALIDADES
                        </MenuItem>
                      )}
                    </TextField>
                  </Grid>
                </>
              )}

              <Grid marginLeft="auto" item>
                <ColorButton
                  onClick={() => {
                    editarCiudadano();
                  }}
                >
                  EDITAR
                </ColorButton>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        {/*Modal para Eliminar */}
        <Modal
          open={openModalEliminar}
          onClose={handleCloseEliminar}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="contenedorModalUrs text-center" sx={style2}>
            <h3 className="TitulosProgramas  mb-5">
              ¿ESTÁ SEGURO DE ELIMINAR AL CIUDADANO?
            </h3>
            <Grid container columns={12} spacing={4}>
              <Grid item xs={12}>
                <h4>{state.ciudadanoSeleccionado.nombre}</h4>
              </Grid>

              <Grid margin="auto" item>
                <ColorButton
                  onClick={() => {
                    eliminarCiudadano();
                  }}
                >
                  ELIMINAR
                </ColorButton>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Ciudadanos;
